import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';

import type { Message, Role } from '@/hooks/chatTypes';
import { adminApi } from '@/services/aiCoreClient';
import { lang } from '@/services/i18n';
import { queryKeys } from '@/services/queryClient';

import { FaqTile } from './FaqTile';

export interface Faq {
  id: string;
  title: string;
  question: string;
  answer: string;
  title_en: string;
  question_en: string;
  answer_en: string;
  click_count: number;
  is_active: boolean;
  created_at: string;
  deleted_at: string | null;
  updated_at: string | null;
}
export interface InsertOptions {
  timeout?: number;
}
interface FaqsProps {
  insert: (
    message: Message | string,
    role?: Role,
    options?: InsertOptions,
  ) => void;
}

export function Faqs({ insert }: FaqsProps) {
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  const { data: faqs } = useQuery({
    queryKey: queryKeys.faqs(),
    queryFn: adminApi.fetchActiveFaqs,
  });

  return (
    <div
      className='flex flex-row gap-4 overflow-x-auto whitespace-nowrap py-3 [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden'
      {...events}
      ref={ref}
    >
      {faqs?.map(faq => (
        <FaqTile
          insert={insert}
          key={faq.id}
          id={faq.id}
          title={lang === 'cs' ? faq.title : faq.title_en}
          Question={lang === 'cs' ? faq.question : faq.question_en}
          Answer={lang === 'cs' ? faq.answer : faq.answer_en}
        />
      ))}
    </div>
  );
}
