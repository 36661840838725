import { X } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import logoImg from '@/assets/images/logo.png';
import { useBoundStore } from '@/store/store';

import { Image } from '../image/Image';

export const TopBar = memo(function TopBar() {
  const { t } = useTranslation();
  const [close, setUnread] = useBoundStore(state => [
    state.close,
    state.setUnread,
  ]);

  /**
   * Close and clear unread messages.
   */
  const handleClose = () => {
    setUnread(0);
    close();
  };

  return (
    <div className='sticky top-0 flex h-16 flex-row items-center justify-between bg-black px-8'>
      <div className='flex items-center gap-3'>
        <Image className='h-6' src={logoImg} />
        <div className='inline-flex rounded bg-darkGrey px-2 py-px text-xs text-lightGrey'>
          {t('topBar.beta')}
        </div>
      </div>
      <div className='flex gap-4'>
        <button
          type='button'
          className='flex items-center justify-center text-lightGrey transition-transform hover:scale-110 hover:text-white active:scale-100'
          onClick={handleClose}
        >
          <X className='size-6' />
        </button>
      </div>
    </div>
  );
});
