import { useCallback, useEffect } from 'react';

import avatarImg from '@/assets/images/avatar.png';
import { NewMembership } from '@/components/newMembership/NewMembership';
import { AppSettings, API_PARAM_PAYMENT } from '@/constants/AppSettings';

import { Bubble } from './components/bubble/Bubble';
import { Chat } from './components/chat/Chat';
import { ChatWindow } from './components/chat/ChatWindow';
import { EFitnessLogin } from './components/eFitnessLogin/EFitnessLogin';
import { MemberShipConsent } from './components/memberShipConsent/MemberShipConsent';
import { NewMember } from './components/newMember/NewMember';
import { NewMemberShipRecap } from './components/newMembershipRecap/NewMembershipRecap';
import { PaymentMembership } from './components/paymentMembership/PaymentMembership';
import { Route } from './components/route/Route';
import { Welcome } from './components/welcome/Welcome';
import { useBoundStore } from './store/store';

export function Widget() {
  const { open, setUnread, pushRoute } = useBoundStore(state => ({
    open: state.open,
    setUnread: state.setUnread,
    pushRoute: state.pushRoute,
  }));
  useEffect(() => {
    const storageKey = AppSettings.app.prefix + '_store';
    const storedData = sessionStorage.getItem(storageKey);
    if (storedData === null) {
      return;
    }
    const userState = JSON.parse(storedData);

    if (!userState || userState.state.user === null) {
      pushRoute('welcome');
    } else {
      pushRoute('chat');
    }
  }, [pushRoute]);

  useEffect(() => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);

    const paramPayment = url.searchParams.get(API_PARAM_PAYMENT);
    if (paramPayment === 'true') {
      open();
    }
  }, [open]);

  const handleOpen = useCallback(() => {
    open();
    setUnread(0);
  }, [open, setUnread]);

  return (
    <>
      <ChatWindow>
        <Route name='welcome'>
          <Welcome />
        </Route>
        <Route name='eFitnessLogin'>
          <EFitnessLogin />
        </Route>
        <Route name='newMembership'>
          <NewMembership />
        </Route>
        <Route name='membershipConsent'>
          <MemberShipConsent />
        </Route>
        <Route name='newMembershipRecap'>
          <NewMemberShipRecap />
        </Route>
        <Route name='membershipPayment'>
          <PaymentMembership />
        </Route>
        <Route name='newMember'>
          <NewMember />
        </Route>
        <Route name='chat'>
          <Chat />
        </Route>
      </ChatWindow>

      <Bubble src={avatarImg} onClick={handleOpen} />
    </>
  );
}
