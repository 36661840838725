import { motion } from 'framer-motion';
import type React from 'react';

interface ContactButtonProps {
  Icon: React.ComponentType<{ className?: string; color?: string }>;
  text: string;
  href: string;
}

export function ContactButton({ Icon, text, href }: ContactButtonProps) {
  return (
    <motion.a
      tabIndex={0}
      href={href}
      className='flex w-36 cursor-pointer flex-row items-center justify-evenly gap-2 bg-red px-4 py-1 font-bold text-white transition duration-300 ease-in-out hover:bg-darkRed hover:shadow-lg'
      initial={{ x: '-100vw', opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <Icon className='size-6' color='white' />
      <span>{text}</span>
    </motion.a>
  );
}
