import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import cs from '../assets/locales/cs.json';
import en from '../assets/locales/en.json';

export const DEFAULT_NAMESPACE = 'glossary';
export const SUPPORTED_LANGUAGES = ['cs', 'en'];

export interface I18nCsResources {
  glossary: typeof cs;
}

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: typeof DEFAULT_NAMESPACE;
    resources: I18nCsResources;
  }
}

// Detect initial language
export const lang =
  window.location.pathname.split('/')[1] === 'en' ? 'en' : 'cs';

i18n.use(initReactI18next).init({
  fallbackLng: lang,
  interpolation: {
    // Not needed for react as it escapes by default
    escapeValue: false,
  },
  defaultNS: DEFAULT_NAMESPACE,
  supportedLngs: SUPPORTED_LANGUAGES,
  resources: {
    cs: {
      glossary: cs,
    },
    en: {
      glossary: en,
    },
  },
});

export { i18n };
