import { create, type StateCreator } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { AppSettings } from '@/constants/AppSettings';

import { createAppSlice, type AppSlice } from './slices/appSlice';
import { createRouterSlice, type RouterSlice } from './slices/routerSlice';
import { createThreadsSlice, type ThreadsSlice } from './slices/threadsSlice';
import { createUserSlice, type UserSlice } from './slices/userSlice';

interface StoreType extends UserSlice, AppSlice, ThreadsSlice, RouterSlice {}

/**
 * Middleware for zustand store adding devtools and
 * persisting JWT token to session storage
 */
const middleware = (f: StateCreator<StoreType>) =>
  persist(f, {
    name: `${AppSettings.app.prefix}_store`,
    storage: createJSONStorage(() => sessionStorage),
    partialize: state => ({
      user: state.user,
      member: state.member,
      membership: state.membership,
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
      route: state.route,
      opened: state.opened,
    }),
  });

export const useBoundStore = create<StoreType>()(
  middleware((...args) => ({
    ...createAppSlice(...args),
    ...createThreadsSlice(...args),
    ...createUserSlice(...args),
    ...createRouterSlice(...args),
  })),
);
