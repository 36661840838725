/**
 * Checks if a token is expired. Adds a margin of error to the expiration time.
 */
export function isTokenExpired(expires: Date, margin = 300): boolean {
  const date = new Date(expires);
  date.setSeconds(date.getSeconds() - margin);

  return date < new Date();
}

export const preprocessMarkdown = (markdownText: string) => {
  // This regex targets lines between triple backticks
  const codeBlockRegex = /```[\S\s]*?```/g;

  return markdownText.replaceAll(codeBlockRegex, '');
};
