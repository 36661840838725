import { QueryClient } from '@tanstack/react-query';

export const defaultQueryClient = new QueryClient();

/**
 * Chatbot-wide query keys used for cache invalidation
 * @see https://react-query.tanstack.com/guides/query-keys
 */
export const queryKeys = {
  chat: () => ['chat'] as const,
  userChat: (userId: string | undefined) => ['chat', userId] as const,
  threads: () => ['threads'] as const,
  userThreads: (userId: string | undefined) => ['threads', userId] as const,
  authCheck: () => ['authCheck'] as const,
  membershipDefinition: () => ['membershipDefinition'] as const,
  membershipConsents: () => ['membershipConsents'] as const,
  faqs: () => ['faqs'] as const,
  operators: () => ['operators'] as const,
} as const;
