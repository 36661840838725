import type { TypedFormState } from '@utima/ui-informed';
import type React from 'react';

import MultipleStepFormProvider from '@/components/multistepForm/MultipleStepFormContext';
import MultipleStepFormControl from '@/components/multistepForm/MultipleStepFormControl';

export interface MultiStepFormProps {
  children: React.ReactNode;
  showStepCounter?: boolean;
  showPreviousButton?: boolean;
  // TODO: I think we should pass the FormValues Type information as generic
  onSubmit: (values: TypedFormState<any>) => void;
}

function MultipleStepForm({ children, ...rest }: MultiStepFormProps) {
  return (
    <MultipleStepFormProvider>
      <MultipleStepFormControl {...rest}>{children}</MultipleStepFormControl>
    </MultipleStepFormProvider>
  );
}

export default MultipleStepForm;
