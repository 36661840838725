import { Button } from '@utima/ui';
import clsx from 'clsx';
import { useRef, type ComponentPropsWithoutRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FeedbackTextAreaProps
  extends Omit<ComponentPropsWithoutRef<'textarea'>, 'onSubmit'> {
  initialMinHeight?: string;
  onSubmit: (text: string) => void;
}

export const FeedbackTextArea = memo(function FeedbackTextArea({
  onSubmit,
  placeholder,
  initialMinHeight,
  className,
  ...restProps
}: FeedbackTextAreaProps) {
  const { t } = useTranslation();
  const [text, setText] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = () => {
    const textarea = textareaRef.current;

    if (!textarea) {
      return;
    }

    setText(textarea.value);
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleSubmit = () => {
    onSubmit(text);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx('flex w-full flex-col items-end gap-2', className)}
    >
      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleChange}
        placeholder={placeholder}
        className='w-full resize-none overflow-hidden rounded-md border border-gray-300 bg-white p-3 text-sm text-black placeholder:text-opacity-50 focus:outline-none'
        style={{ minHeight: initialMinHeight || '50px' }}
        {...restProps}
      />
      <Button
        type='button'
        onClick={handleSubmit}
        disabled={!text}
        size='sm'
        className='px-6 font-normal'
      >
        {t('feedback.send')}
      </Button>
    </form>
  );
});
