import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import {
  useEffect,
  useState,
  type ComponentProps,
  type ComponentPropsWithoutRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import avatarImg from '@/assets/images/avatar.png';
import type { Role } from '@/hooks/chatTypes';
import { preprocessMarkdown } from '@/lib/utils';

import { ActivityIndicator } from './ActivityIndicator';
import { TimeAgo } from './TimeAgo';
import { UserAvatar } from './UserAvatar';
import { Feedback } from '../feedback/Feedback';
// import { Timer } from '../timer/Timer';

interface MessageBoxProps
  extends ComponentPropsWithoutRef<'div'>,
    Omit<ComponentProps<'div'>, 'role'> {
  conversationId?: string;
  text?: string;
  isTyping?: boolean;
  role: Role;
  createdAt?: string;
}

export function MessageBox({
  text,
  className,
  isTyping = false,
  role = 'user',
  createdAt = new Date().toISOString(),
  conversationId,
  ...restProps
}: MessageBoxProps) {
  const [textMarked, setTextMarked] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    async function load() {
      if (!text) {
        return;
      }

      try {
        const textMarked = await marked(DOMPurify.sanitize(text));
        const textMarkedRemove = preprocessMarkdown(textMarked);
        setTextMarked(textMarkedRemove);
      } catch (error) {
        console.error(error);
      }
    }
    load();
  }, [text]);

  return (
    <div className={clsx('relative', className)} {...restProps}>
      <span
        className={clsx('block pb-2 leading-6 text-white', {
          ['text-right']: role === 'user',
          ['text-left']: role === 'system',
        })}
      >
        {role === 'user' ? t('messageBox.user') : t('messageBox.system')}
      </span>

      <div
        className={clsx(
          'flex items-end gap-2',
          {
            ['flex-row']: role === 'system',
            ['flex-row-reverse']: role === 'user',
          },
          className,
        )}
      >
        <UserAvatar src={role === 'system' ? avatarImg : undefined} />
        <div
          className={clsx(
            'flex max-w-[75%] rounded-2xl px-3.5 py-3 text-white',
            {
              ['rounded-br-none bg-darkGrey ml-auto']: role === 'user',
              ['rounded-bl-none bg-red']: role === 'system',
            },
          )}
        >
          {isTyping ? (
            <ActivityIndicator />
          ) : (
            <div
              style={{ overflowWrap: 'anywhere' }}
              className='flex break-before-auto flex-col gap-2'
              dangerouslySetInnerHTML={{
                __html: textMarked,
              }}
            />
          )}
        </div>
      </div>

      {!isTyping && (
        <TimeAgo
          className={clsx({
            ['text-right']: role === 'user',
            ['text-left']: role === 'system',
          })}
          date={createdAt}
        />
      )}

      {role === 'system' && !isTyping && (
        <Feedback conversationId={conversationId} />
      )}
    </div>
  );
}
