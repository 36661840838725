import { Button } from '@utima/ui';
import { LogIn, LogOut } from 'lucide-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthSource } from '@/store/slices/userSlice';
import { useBoundStore } from '@/store/store';

export const ActionBar = memo(function ActionBar() {
  const { t } = useTranslation();
  const { user, logout, pushRoute } = useBoundStore(state => ({
    user: state.user,
    logout: state.logout,
    pushRoute: state.pushRoute,
  }));

  return (
    <div className='bottom-0 flex flex-row items-center justify-center bg-black px-8 py-5'>
      <div className='flex w-full justify-between gap-4'>
        <Button
          size='xl'
          variant='primary'
          onClick={() =>
            pushRoute(
              user?.source === AuthSource.Efitness
                ? 'newMembership'
                : 'newMember',
            )
          }
        >
          {t('membershipRegistration.newMembership')}
        </Button>
        {user?.source !== AuthSource.Efitness && (
          <Button
            variant='info'
            size='sm'
            className='!rounded-full px-4 font-normal'
            onClick={() => pushRoute('eFitnessLogin')}
          >
            <LogIn className='size-4' /> {t('welcome.login')}
          </Button>
        )}
        {user?.source === AuthSource.Efitness && (
          <Button
            variant='info'
            size='sm'
            className='!rounded-full px-4 font-normal'
            onClick={logout}
          >
            <LogOut className='size-4' /> {t('topBar.logout')}
          </Button>
        )}
      </div>
    </div>
  );
});
