import type { FormState } from 'informed';

/**
 * Combine two FormState objects into one.
 * @param source - The first FormState object (the one that will be overwritten by `target` param if collision happens).
 * @param target - The second FormState object (the one that will overwrite `source` param if collision happens).
 */
export const combineFormState = (
  source: FormState,
  target: FormState,
): FormState => {
  return {
    values: { ...source.values, ...target.values },
    errors: { ...source.errors, ...target.errors },
    touched: { ...source.touched, ...target.touched },
    modified: { ...source.modified, ...target.modified },
    maskedValues: { ...source.maskedValues, ...target.maskedValues },
    dirt: { ...source.dirt, ...target.dirt },
    focused: { ...source.focused, ...target.focused },
    initialValues: { ...source.initialValues, ...target.initialValues },
    data: { ...source.data, ...target.data },
    memory: { ...source.memory, ...target.memory },
    valid: source.valid && target.valid,
    validating: source.validating && target.validating,
    gathering: source.gathering && target.gathering,
    submitted: source.submitted && target.submitted,
    submitting: source.submitting && target.submitting,
    invalid: source.invalid && target.invalid,
    pristine: source.pristine && target.pristine,
    dirty: source.dirty && target.dirty,
    disabled: source.disabled && target.disabled,
  };
};
