import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocationName } from '@/constants/AppSettings';
import { aiCoreApi } from '@/services/aiCoreClient';
import { lang } from '@/services/i18n';
import { useBoundStore } from '@/store/store';

import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';
import { getPeriodTypeName } from '../newMembership/NewMembership';

interface RegistrationMemberData {
  clubId: number;
  membershipId: number;
  date: string;
  consentsIds: number[];
  description?: string;
  installmentsNumber: number;
  name: string;
  price: number;
  periodTime: number;
  periodType: number;
  discountCoupon?: string;
}

interface AccessToken {
  token: string;
}

interface ApiResponse {
  installmentIdsForPayment: string[];
}

interface MutationParams {
  params: {
    clubId: number;
    membershipDefinitionId: number;
    agreementFrom: string;
    selectedConsentIds: number[];
    selectedServiceIds: number[];
    discountCode?: string;
  };
  headers: {
    memberToken: string;
  };
}

export function NewMemberShipRecap() {
  const { t } = useTranslation();
  const { pushRoute, popRoute, user } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
    user: state.user,
  }));
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [accessToken, registrationMemberData, setRegistrationMemberData] =
    useBoundStore(state => [
      state.accessToken as AccessToken,
      state.registrationMemberData as RegistrationMemberData,
      state.setRegistrationMemberData,
    ]);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const mutation: UseMutationResult<ApiResponse, any, MutationParams> =
    useMutation({
      mutationFn: async ({ params, headers }) => {
        return aiCoreApi.postMembership({ data: params, headers: headers });
      },
      onSuccess: data => {
        setRegistrationMemberData({
          ...registrationMemberData,
          invoiceID: data.installmentIdsForPayment,
        });
        pushRoute('membershipPayment');
      },
      onError: async (err: any) => {
        const errorDecoded = await err.response?.json();
        if (errorDecoded.response.data.errors) {
          setErrorMessages(errorDecoded.response.data.errors);
        } else {
          setErrorMessages(['Something went wrong']);
        }
      },
    });

  const createMemberShip = async () => {
    const params = {
      clubId: registrationMemberData.clubId,
      membershipDefinitionId: registrationMemberData.membershipId,
      agreementFrom: registrationMemberData.date,
      selectedConsentIds: registrationMemberData.consentsIds,
      selectedServiceIds: [],
      discountCoupon: registrationMemberData.discountCoupon,
      userInfo: {
        id: user?.externalId,
        name: user?.name,
        email: user?.email,
        club: getLocationName(Number(registrationMemberData.clubId)),
        membershipName: registrationMemberData.name,
      },
    };
    const headers = {
      memberToken: `Bearer ${accessToken?.token}`,
      language: lang === 'cs' ? 'cs-CZ' : 'en-US',
    };

    await mutation.mutateAsync({ params, headers });
  };

  const descriptionItems = registrationMemberData?.description
    ? registrationMemberData.description.split('||')
    : [];

  return (
    <div className='size-full items-center justify-stretch overflow-auto p-8'>
      <div className='w-full rounded-2xl bg-darkGrey p-5 [&_*]:ring-offset-darkGrey'>
        <div className='relative mb-2 flex w-full items-center justify-center'>
          <Button
            onClick={() => popRoute()}
            className='absolute left-0 top-0 flex text-lg font-medium'
            size='icon-sm'
          >
            <ChevronLeft />
          </Button>
          <h4 className='text-center text-3xl font-normal leading-10 text-white'>
            {t('membershipRegistration.recapitulation')}
          </h4>
        </div>
        <div className='flex flex-col text-white'>
          <p>
            <span className=' font-bold'>
              {t('membershipRegistration.club')}:
            </span>{' '}
            {getLocationName(Number(registrationMemberData?.clubId))}
          </p>
          <p>
            <span className='font-bold'>
              {t('membershipRegistration.membership')}:
            </span>{' '}
            {registrationMemberData?.name}
          </p>
          <p>
            <span className='font-bold'>
              {t('membershipRegistration.date')}:
            </span>{' '}
            {registrationMemberData?.date}
          </p>
          <p>
            <span className='font-bold'>
              {t('membershipRegistration.price')}:
            </span>{' '}
            {registrationMemberData?.price} Kč
            {registrationMemberData?.installmentsNumber === 1
              ? ''
              : registrationMemberData?.installmentsNumber === 2 ||
                  registrationMemberData?.installmentsNumber === 12
                ? '/ ' + t('membershipRegistration.monthly')
                : ''}
          </p>
          <p>
            <span className='font-bold'>
              {t('membershipRegistration.description')}:
            </span>{' '}
            {registrationMemberData?.description && (
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {descriptionItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </p>
          <p>
            <span className='font-bold'>
              {t('membershipRegistration.contractPeriod')}:
            </span>{' '}
            {registrationMemberData?.periodTime}{' '}
            {getPeriodTypeName(
              registrationMemberData?.periodType,
              t,
              registrationMemberData?.periodTime,
              'cz',
            )}
          </p>
          {mutation.error &&
            errorMessages.length > 0 &&
            errorMessages.map((error: any) => (
              <p
                key={error.responseCode}
                className='text-center text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'
              >
                {error.message}
              </p>
            ))}

          <ConfirmDialog
            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            onConfirm={createMemberShip}
          />

          <Button
            size='lg'
            type='submit'
            className='mt-4'
            onClick={() => setIsDialogOpen(true)}
            disabled={mutation.isPending}
          >
            {t('membershipRegistration.createMembership')}
          </Button>
        </div>
      </div>
    </div>
  );
}
