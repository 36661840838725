import { useQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API_PARAM_PAYMENT } from '@/constants/AppSettings';
import { createMessage, useChat } from '@/hooks/useChat';
import { useDelayedLoading } from '@/hooks/useDelayedLoading';
import {
  aiCoreApi,
  eFitnessRefreshHook,
  memberDataRefreshHook,
  membershipDataRefreshHook,
} from '@/services/aiCoreClient';
import { queryKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

import { ActionBar } from './ActionBar';
import { Faqs } from '../faqs/Faqs';
import { HelpContact } from '../helpContact/HelpContact';
import { InputBox } from '../inputBox/InputBox';
import { MessageBox } from '../message/MessageBox';

export function Chat() {
  const { t } = useTranslation();
  const [
    user,
    threadId,
    setThreadId,
    opened,
    setUnread,
    showHelp,
    setShowHelp,
    member,
    membership,
  ] = useBoundStore(state => [
    state.user,
    state.threadId,
    state.setThreadId,
    state.opened,
    state.setUnread,
    state.showHelp,
    state.setShowHelp,
    state.member,
    state.membership,
  ]);

  const [messageCount, setMessageCount] = useState(0);
  const lastMessageCountRef = useRef(messageCount);

  /**
   * Fetch user threads and create a new thread if it does not exist.
   * This will be used to store the chat history.
   */
  const threadsQuery = useQuery({
    staleTime: Number.POSITIVE_INFINITY,
    queryKey: queryKeys.userThreads(user?.id),
    queryFn: async () => {
      if (!user?.id) {
        return null;
      }

      return aiCoreApi.getThreads(
        { userId: user.id },
        {
          hooks: {
            // Refresh efitness refresh token
            beforeRequest: [
              eFitnessRefreshHook,
              memberDataRefreshHook,
              membershipDataRefreshHook,
            ],
            afterResponse: [
              // @ts-expect-error there is slight type mismatch
              async (_input, _options, response) => {
                // Create new thread
                if (response.status === 404) {
                  const newThread = await aiCoreApi.createThreads({
                    userId: user.id,
                  });

                  return [newThread];
                }

                return response;
              },
            ],
          },
        },
      );
    },
    enabled: !!user?.id && opened,
  });

  /**
   * Set the thread id to the first thread in the list.
   * This will be used to store the chat history.
   */
  useEffect(() => {
    if (Array.isArray(threadsQuery.data) && threadsQuery.data.length > 0) {
      setThreadId(threadsQuery.data[0].id);
    }
  }, [threadsQuery.data, setThreadId]);

  /**
   * Always set unread, this should be cleaned up when clicking close button.
   */
  const handleMessage = useCallback(() => {
    setUnread(1);
    setMessageCount(1);
  }, [setUnread, setMessageCount]);

  useEffect(() => {
    lastMessageCountRef.current = messageCount;
    const timer = setTimeout(() => {
      if (
        messageCount === lastMessageCountRef.current &&
        messageCount >= 5 &&
        user?.externalId
      ) {
        setShowHelp(true);
      }
    }, 30000); // 30 seconds

    return () => clearTimeout(timer);
  }, [messageCount, setShowHelp, user?.externalId]);

  /**
   * This handles the chat logic, it fetches the messages from the API
   * and sends new messages to the API.
   */
  const {
    input,
    handleInputChange,
    messages,
    handleSubmit,
    isLoading,
    isInitialized,
    abort,
    insert,
  } = useChat({
    user,
    threadId,
    initialMessages: [createMessage(t('messageBox.initial'), 'system')],
    onMessage: handleMessage,
    member,
    membership,
  });

  // Payment logic
  useEffect(() => {
    if (!isInitialized) return;
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const paramPayment = url.searchParams.get(API_PARAM_PAYMENT);
    if (paramPayment === 'true') {
      url.searchParams.delete(API_PARAM_PAYMENT);
      insert(createMessage(t('messageBox.finishedPayment'), 'system'));
      window.history.replaceState({}, document.title, url.toString());
    }
  }, [isInitialized, insert, t]);

  const throttledIsLoading = useDelayedLoading(isLoading, 750);

  return (
    <form className='relative flex h-full flex-col' onSubmit={handleSubmit}>
      <ActionBar />
      <div className='flex grow flex-col-reverse overflow-y-auto px-5 py-4'>
        <div className='form-factory-chat__messages flex grow flex-col gap-6 [&>*:first-child]:mt-auto'>
          <AnimatePresence mode='popLayout'>
            {messages.map(
              ({ id, role, content, createdAt, conversationId }) => (
                <motion.div
                  key={id}
                  initial={{ y: 15, opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  <MessageBox
                    role={role}
                    text={content}
                    conversationId={conversationId}
                    createdAt={createdAt}
                  />
                </motion.div>
              ),
            )}
            {throttledIsLoading && (
              <motion.div
                initial={{ y: 15, opacity: 0 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ y: 15, opacity: 0 }}
              >
                <MessageBox key='loader' role='system' isTyping />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <div className='shrink-0 bg-black px-5 pb-4'>
        {showHelp && <HelpContact />}
        <Faqs insert={insert} />
        <InputBox
          value={input}
          onChange={handleInputChange}
          onAbort={abort}
          loading={isLoading}
          initializing={!isInitialized}
        />
      </div>
    </form>
  );
}
