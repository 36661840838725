import { format as dateFnsFormat } from 'date-fns';

/**
 * Parses given date to Date object. If date is undefined, returns undefined.
 */
export function parseDate<T extends Date | number | string>(
  date: T | undefined,
) {
  if (!date) {
    return;
  }

  // Return date object
  if (date instanceof Date) {
    return date;
  }

  // Parse serialized Date string
  if (typeof date === 'string' || typeof date === 'number') {
    return new Date(date);
  }

  return date as Date;
}

/**
 * Custom date formats for date-fns library. Used in `dateFormat` function.
 */
export const DateFormat = Object.freeze({
  Time: 'H:mm', // 14:30
  Date: `d.\u{00A0}MMMM\u{00A0}yyyy`, // 28. listopadu 2023
  DateNumeric: `d.\u{00A0}M.\u{00A0}yyyy`, // 28. 11. 2023
  DateTime: `d.\u{00A0}MMMM\u{00A0}yyyy, H:mm`, // 28. listopadu 2023, 14:30
  DateTimeNumeric: `d.\u{00A0}M.\u{00A0}yyyy, H:mm`, // 28. 11. 2023, 14:30
  DateInput: `yyyy-MM-dd`, // 2023-11-28
  MonthShort: `LLL`, // listopadu 2023
  Month: `LLLL`, // listopad
  MonthYear: `LLLL\u{00A0}yyyy`, // listopad 2023
});

/**
 * Formats given date to string representation.
 */
function formatDate(
  date: Date | string | undefined,
  format: string = DateFormat.Date,
): string {
  const parsedDate = parseDate(date);

  if (!parsedDate) {
    return '';
  }

  return dateFnsFormat(parsedDate, format);
}

/**
 * Collection of formatters, that can be used to format
 * multiple data for display.
 */
export const format = {
  date: formatDate,
};
