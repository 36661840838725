import { useMutation } from '@tanstack/react-query';
import { Button, SelectPrimitive } from '@utima/ui';
import { Form, Select, Input, type TypedFormState } from '@utima/ui-informed';
import { HTTPError } from 'ky';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { aiCoreApi } from '@/services/aiCoreClient';
import { lang } from '@/services/i18n';
import { AuthSource } from '@/store/slices/userSlice';
import { useBoundStore } from '@/store/store';

type FormValues = {
  club: string;
  username: string;
  password: string;
};

export function EFitnessLogin() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { login, pushRoute, popRoute, setUser, setMemberData, setMembership } =
    useBoundStore(state => ({
      login: state.login,
      pushRoute: state.pushRoute,
      popRoute: state.popRoute,
      setUser: state.setUser,
      setMemberData: state.setMemberData,
      setMembership: state.setMembership,
    }));

  /**
   * Authenticate users against eFitness API,
   * this will create a new user in the database if it does not exist.
   */
  const { mutateAsync, error } = useMutation({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      const { username, password, club } = formState.values;
      const data = await aiCoreApi.authEFitness(
        {
          club,
          username,
          password,
        },
        {
          headers: {
            language: lang === 'cs' ? 'cs-CZ' : 'en-US',
          },
        },
      );

      login(
        {
          id: data.user.id,
          externalId: data.user.externalId,
          source: AuthSource.Efitness,
          club: club,
        },
        {
          token: data.memberToken.accessToken,
          expires: data.memberToken.expires,
        },
        data.memberToken.refreshToken,
      );

      setUser({
        id: data.user.id,
        externalId: data.user.externalId,
        source: AuthSource.Efitness,
        club: club,
        name: data.memberData.firstName + data.memberData.lastName,
        email: data.memberData.email,
      });

      setMemberData(data.memberData);
      setMembership(data.membership);

      // Redirect to chat
      pushRoute('chat');

      return { data, club };
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  return (
    <div className='flex size-full items-center justify-stretch'>
      <div className='m-8 w-full rounded-2xl bg-darkGrey p-5 [&_*]:ring-offset-darkGrey'>
        <div className='relative mb-2 flex w-full items-center justify-center'>
          <Button
            onClick={() => popRoute()}
            className='absolute left-0 top-0 flex text-lg font-medium'
            size='icon-sm'
          >
            <ChevronLeft />
          </Button>
          <h4 className='text-center text-3xl font-normal leading-10 text-white'>
            {t('welcome.login')}
          </h4>
        </div>
        <Form onSubmit={mutateAsync} className='flex flex-col gap-5'>
          <Select
            size='lg'
            defaultValue='895'
            required
            name='club'
            usePortal={false}
            label={t('loginForm.club')}
          >
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Praha</SelectPrimitive.Label>
              <Select.Item value='895'>Anděl</Select.Item>
              <Select.Item value='1185'>Butovice</Select.Item>
              <Select.Item value='1184'>Eden</Select.Item>
              <Select.Item value='1186'>Harfa</Select.Item>
              <Select.Item value='1048'>Hostivař</Select.Item>
              <Select.Item value='896'>Karlín</Select.Item>
              <Select.Item value='2087'>Letňany</Select.Item>
              <Select.Item value='1187'>Palladium</Select.Item>
              <Select.Item value='1760'>Pankrác</Select.Item>
              <Select.Item value='2086'>SO-HO</Select.Item>
              <Select.Item value='1042'>Stodůlky</Select.Item>
              <Select.Item value='1041'>Václavské náměstí</Select.Item>
              <Select.Item value='1043'>Vinohradská</Select.Item>
              <Select.Item value='1047'>Vršovická</Select.Item>
            </SelectPrimitive.Group>
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Brno</SelectPrimitive.Label>
              <Select.Item value='1912'>Lužánky</Select.Item>
              <Select.Item value='1911'>Vlněna</Select.Item>
            </SelectPrimitive.Group>
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Ostrava</SelectPrimitive.Label>
              <Select.Item value='1804'>Ostrava Avion</Select.Item>
            </SelectPrimitive.Group>
          </Select>
          <Input
            size='lg'
            required
            name='username'
            label={t('loginForm.username')}
          />
          <Input
            size='lg'
            required
            name='password'
            label={t('loginForm.password')}
            type='password'
            passwordPreview
          />
          {error && (
            <p className='text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'>
              {error instanceof HTTPError && error.response.status === 401
                ? t('loginForm.errors.401')
                : t('loginForm.errors.500')}
            </p>
          )}
          <Button
            size='lg'
            type='submit'
            disabled={isLoading}
            loading={isLoading}
            className='mt-8'
          >
            {t('loginForm.submit')}
          </Button>
        </Form>
      </div>
    </div>
  );
}
