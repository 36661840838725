import { Loader2, Send, Square } from 'lucide-react';
import {
  memo,
  useCallback,
  useDeferredValue,
  type ChangeEvent,
  type MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';

import { CharacterCounter } from '../characterCounter/CharacterCounter';
import { ResetConversation } from '../resetConversation/ResetConversation';

type InputBoxProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onAbort?: () => void;
  value: string;
  loading?: boolean;
  initializing?: boolean;
  maxLength?: number;
};

export const InputBox = memo(function InputBox({
  onChange,
  onAbort,
  value,
  loading,
  initializing,
  maxLength = 300,
}: InputBoxProps) {
  const { t } = useTranslation();
  const deferredValue = useDeferredValue(value);

  /**
   * Handle the submission of the input box.
   * If the input box is loading, it will abort the submission.
   */
  const handleSubmit = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // Don't do anything if there is no onAbort function
      if (!onAbort) {
        return;
      }

      // Prevent submission and abort
      if (loading) {
        event.preventDefault();
        onAbort();
      }
    },
    [onAbort, loading],
  );

  return (
    <div className='flex flex-col gap-2'>
      <div className='relative'>
        <input
          type='text'
          className='relative flex h-16 w-full min-w-full appearance-none rounded-full bg-darkGrey pl-8 pr-20 text-lg text-white outline-none transition-all placeholder:text-lightGrey focus:bg-grey disabled:cursor-not-allowed disabled:opacity-75'
          placeholder={
            loading
              ? t('inputBox.waiting')
              : initializing
                ? t('inputBox.initializing')
                : t('inputBox.ready')
          }
          maxLength={maxLength}
          value={value}
          disabled={loading || initializing}
          onChange={onChange}
        />
        <div className='absolute inset-y-0 right-0 flex items-center pr-2'>
          <button
            type='submit'
            className='flex size-12 cursor-pointer items-center justify-center rounded-full bg-red transition-all enabled:hover:scale-105 enabled:hover:bg-lightRed enabled:active:scale-100 disabled:cursor-auto disabled:opacity-75'
            disabled={(loading && !onAbort) || initializing}
            onClick={handleSubmit}
          >
            {initializing && !loading ? (
              <Loader2 className='size-6 animate-spin text-white' />
            ) : loading && onAbort ? (
              <Square className='size-6 text-white' />
            ) : (
              <Send className='size-6 text-white' />
            )}
          </button>
        </div>
      </div>
      <div className='flex items-center justify-between'>
        <ResetConversation onReset={onAbort} />
        <CharacterCounter max={maxLength} current={deferredValue?.length} />
      </div>
    </div>
  );
});
