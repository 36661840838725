import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, SelectPrimitive } from '@utima/ui';
import { Form, Select, type TypedFormState, Input } from '@utima/ui-informed';
import { HTTPError } from 'ky';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { aiCoreApi } from '@/services/aiCoreClient';
import { lang } from '@/services/i18n';
import { queryKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';
import type { MembershipDefinition } from '@/types/membershipDefinition';

type FormValues = {
  club: string;
  membership: string;
  date: string;
  discountCoupon?: string;
};

enum PeriodType {
  Day = 0,
  Week = 1,
  Month = 2,
  All = 3,
  FourWeeks = 4,
  TwoWeeks = 5,
  ThreeMonths = 6,
  Settlement = 7,
}

const translationsPeriod: {
  [key: string]: {
    [key in PeriodType]: {
      singular: string;
      plural: string;
    };
  };
} = {
  en: {
    [PeriodType.Day]: { singular: 'Day', plural: 'Days' },
    [PeriodType.Week]: { singular: 'Week', plural: 'Weeks' },
    [PeriodType.Month]: { singular: 'Month', plural: 'Months' },
    [PeriodType.All]: { singular: 'All', plural: 'All' },
    [PeriodType.FourWeeks]: { singular: 'Four Weeks', plural: 'Four Weeks' },
    [PeriodType.TwoWeeks]: { singular: 'Two Weeks', plural: 'Two Weeks' },
    [PeriodType.ThreeMonths]: {
      singular: 'Three Months',
      plural: 'Three Months',
    },
    [PeriodType.Settlement]: { singular: 'Settlement', plural: 'Settlements' },
  },
  cz: {
    [PeriodType.Day]: { singular: 'Den', plural: 'Dnů' },
    [PeriodType.Week]: { singular: 'Týden', plural: 'Týdnů' },
    [PeriodType.Month]: { singular: 'Měsíc', plural: 'Měsíců' },
    [PeriodType.All]: { singular: 'Všechno', plural: 'Všechno' },
    [PeriodType.FourWeeks]: { singular: 'Čtyři týdny', plural: 'Čtyři týdny' },
    [PeriodType.TwoWeeks]: { singular: 'Dva týdny', plural: 'Dva týdny' },
    [PeriodType.ThreeMonths]: { singular: 'Tři měsíce', plural: 'Tři měsíce' },
    [PeriodType.Settlement]: { singular: 'Vyrovnání', plural: 'Vyrovnání' },
  },
  // Add more languages as needed
};

// TODO @jsimck type this
export const getPeriodTypeName = (
  periodId: number | undefined,
  t: any,
  count: number | undefined,
  language: string,
): string => {
  const langTranslationsPeriod = translationsPeriod[language];
  if (periodId === null || periodId === undefined) {
    return t('membershipRegistration.undefineContract');
  }
  const translation = langTranslationsPeriod[periodId as PeriodType];

  return count === 1 ? translation.singular : translation.plural;
};

export function NewMembership() {
  const { t } = useTranslation();
  const [clubId, setClubId] = useState<string | undefined>(undefined);
  const [membershipDetails, setMembershipDetails] = useState<{
    price: number | undefined;
    description: string | undefined;
    periodType: number | undefined;
    periodTime: number | undefined;
    installmentsNumber: number | undefined;
    name: string | undefined;
  }>({
    price: undefined,
    description: undefined,
    periodType: undefined,
    periodTime: undefined,
    installmentsNumber: undefined,
    name: undefined,
  });

  const descriptionItems = membershipDetails.description
    ? membershipDetails.description.split('||')
    : [];
  const today = new Date().toISOString().split('T')[0];
  const [setRegistrationMemberData] = useBoundStore(state => [
    state.setRegistrationMemberData,
  ]);
  const [isMembershipSelected, setIsMembershipSelected] = useState(false);

  const { pushRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
  }));

  const { data, refetch } = useQuery<{
    results: MembershipDefinition[];
  }>({
    queryKey: queryKeys.membershipDefinition(),
    queryFn: async () => {
      if (!clubId) return { results: [] };

      return aiCoreApi.getMembershipDefinitions({
        clubId: clubId,
        language: lang === 'cs' ? 'cs-CZ' : 'en-US',
      });
    },
    retry: 0,
    enabled: false,
  });

  useEffect(() => {
    if (clubId) {
      refetch();
    }
  }, [clubId, refetch]);

  const { mutateAsync, error } = useMutation<
    void,
    HTTPError,
    TypedFormState<FormValues>
  >({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      const { club, membership, date, discountCoupon } = formState.values;
      setRegistrationMemberData({
        clubId: club,
        membershipId: membership,
        date: date,
        price: membershipDetails.price,
        description: membershipDetails.description,
        periodType: membershipDetails.periodType,
        periodTime: membershipDetails.periodTime,
        installmentsNumber: membershipDetails.installmentsNumber,
        name: membershipDetails.name,
        discountCoupon: discountCoupon,
      });
      pushRoute('membershipConsent');
    },
  });

  return (
    <div className='size-full items-center justify-stretch overflow-auto p-8'>
      <div className=' w-full rounded-2xl bg-darkGrey p-5 [&_*]:ring-offset-darkGrey'>
        <div className='relative mb-2 flex w-full items-center justify-center'>
          <Button
            onClick={() => pushRoute('chat')} // need to be pushed back to chat because user can go trough login page
            className='absolute left-0 top-0 flex text-lg font-medium'
            size='icon-sm'
          >
            <ChevronLeft />
          </Button>
          <h4 className='text-center text-3xl font-normal leading-10 text-white'>
            {t('membershipRegistration.newMembership')}
          </h4>
        </div>
        <Form onSubmit={mutateAsync} className='flex flex-col gap-5'>
          <Select
            size='lg'
            defaultValue=''
            placeholder={t('membershipRegistration.chooseClub')}
            required
            name='club'
            usePortal={false}
            label={t('membershipForm.club')}
            onChange={(e: any) => {
              if (e.value === '') return;
              setClubId(e.value);
            }}
          >
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Praha</SelectPrimitive.Label>
              <Select.Item value='895'>Anděl</Select.Item>
              <Select.Item value='1185'>Butovice</Select.Item>
              <Select.Item value='1184'>Eden</Select.Item>
              <Select.Item value='1186'>Harfa</Select.Item>
              <Select.Item value='1048'>Hostivař</Select.Item>
              <Select.Item value='896'>Karlín</Select.Item>
              <Select.Item value='2087'>Letňany</Select.Item>
              <Select.Item value='1187'>Palladium</Select.Item>
              <Select.Item value='1760'>Pankrác</Select.Item>
              <Select.Item value='2086'>SO-HO</Select.Item>
              <Select.Item value='1042'>Stodůlky</Select.Item>
              <Select.Item value='1041'>Václavské náměstí</Select.Item>
              <Select.Item value='1043'>Vinohradská</Select.Item>
              <Select.Item value='1047'>Vršovická</Select.Item>
            </SelectPrimitive.Group>
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Brno</SelectPrimitive.Label>
              <Select.Item value='1912'>Lužánky</Select.Item>
              <Select.Item value='1911'>Vlněna</Select.Item>
            </SelectPrimitive.Group>
            <SelectPrimitive.Group>
              <SelectPrimitive.Label>Ostrava</SelectPrimitive.Label>
              <Select.Item value='1804'>Ostrava Avion</Select.Item>
            </SelectPrimitive.Group>
          </Select>
          <Select
            size='lg'
            defaultValue=''
            placeholder={t('membershipRegistration.chooseMembershipType')}
            required
            name='membership'
            usePortal={false}
            label={t('membershipRegistration.membershipType')}
            disabled={!clubId}
            onChange={(e: any) => {
              const selectedDefinition = data?.results.find(
                definition => definition.id.toString() === e.value,
              );
              if (selectedDefinition) {
                setMembershipDetails({
                  price: selectedDefinition.installmentPrice,
                  description: selectedDefinition.internetDescription,
                  periodType: selectedDefinition.periodType,
                  periodTime: selectedDefinition.periodTime,
                  name: selectedDefinition.name,
                  installmentsNumber: selectedDefinition.installmentsNumber,
                });
                setIsMembershipSelected(true);
              }
            }}
          >
            {data?.results
              .filter(
                definition =>
                  definition.id !== 55401 && definition.id !== 52059,
              )
              .map(definition => (
                <Select.Item
                  key={definition.id}
                  value={definition.id.toString()}
                >
                  {definition.name}
                </Select.Item>
              ))}
          </Select>

          <Input
            name='date'
            required
            label={t('membershipRegistration.membershipStart')}
            type='date'
            min={today}
          />
          <Input
            name='discountCode'
            label={t('membershipRegistration.discountCode')}
          />
          {isMembershipSelected && (
            <p className='text-white'>
              <span className='font-bold'>
                {t('membershipRegistration.price')}:
              </span>{' '}
              {membershipDetails.price} Kč{' '}
              {membershipDetails.installmentsNumber === 1
                ? ''
                : membershipDetails.installmentsNumber === 2 ||
                    membershipDetails.installmentsNumber === 12
                  ? '/ ' + t('membershipRegistration.monthly')
                  : ''}
              <br />
              <span className='font-bold'>
                {t('membershipRegistration.description')}:
              </span>
              {membershipDetails.description && (
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  {descriptionItems.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
              <br />
              <span className='font-bold'>
                {t('membershipRegistration.contractPeriod')}:
              </span>{' '}
              {membershipDetails.periodTime}{' '}
              {getPeriodTypeName(
                membershipDetails.periodType,
                t,
                membershipDetails.periodTime,
                'cz',
              )}
            </p>
          )}
          {error && (
            <p className='text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'>
              {error instanceof HTTPError && error.response.status === 401
                ? t('loginForm.errors.401')
                : t('loginForm.errors.500')}
            </p>
          )}
          <Button size='lg' type='submit' className='mt-4'>
            {t('membershipRegistration.continue')}
          </Button>
        </Form>
      </div>
    </div>
  );
}
