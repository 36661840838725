import clsx from 'clsx';
import { X } from 'lucide-react';
import { memo, type ComponentPropsWithoutRef } from 'react';

export interface FeedbackFrameProps extends ComponentPropsWithoutRef<'div'> {
  title?: string;
  onClose?: () => void;
}

export const FeedbackFrame = memo(function FeedbackFrame({
  title,
  children,
  className,
  onClose,
  ...restProps
}: FeedbackFrameProps) {
  return (
    <div
      className={clsx(
        'relative w-full rounded-lg bg-darkGrey px-3.5 py-3 text-white',
        className,
      )}
      {...restProps}
    >
      {onClose && (
        <button
          type='button'
          className='absolute right-0 top-0 p-3 transition-all hover:scale-110 active:scale-100'
          onClick={onClose}
        >
          <X className='size-5' />
        </button>
      )}
      {title && (
        <div className='mb-5 flex flex-col items-center gap-2'>
          {title}
          <span className='h-[2px] w-[96px] bg-red' />
        </div>
      )}
      {children}
    </div>
  );
});
