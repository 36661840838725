import { useMutation } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import loginBg from '@/assets/images/login-bg.jpeg';
import { aiCoreApi } from '@/services/aiCoreClient';
import { AuthSource } from '@/store/slices/userSlice';
import { useBoundStore } from '@/store/store';

export const Welcome = memo(function Welcome() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { login, pushRoute } = useBoundStore(state => ({
    login: state.login,
    pushRoute: state.pushRoute,
  }));

  /**
   * Authenticate users agains API, this will create a new user
   * in the database if it does not exist. Otherwise it will return
   * the existing user.
   */
  const { mutateAsync: authenticate } = useMutation({
    mutationFn: async () => {
      const data = await aiCoreApi.authLocal({
        userId: uuid(),
      });

      login({
        ...data.user,
        source: AuthSource.Local,
      });

      pushRoute('chat');
    },
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  return (
    <div className='relative flex size-full items-center justify-center'>
      <img
        loading='lazy'
        className='absolute left-0 top-0 size-full object-cover opacity-20'
        src={loginBg}
      />

      <div className='relative z-10 flex w-full flex-col p-8'>
        <div>
          <div className='flex flex-col gap-4 text-[32px] font-semibold uppercase text-neutral-100'>
            <p>{t('welcome.title')}</p>
            <p>{t('welcome.subtitle')}</p>
          </div>
          <p className='mt-10 text-2xl font-medium text-neutral-100'>
            {t('welcome.description')}
          </p>
        </div>
        <div className='mt-24 flex w-full flex-col gap-5'>
          <Button
            size='xl'
            variant='primary'
            onClick={() => pushRoute('eFitnessLogin')}
          >
            {t('welcome.loginEFitness')}
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={() => authenticate()}
            size='xl'
            variant='primary'
            outline
            className='hover:bg-white/90 active:bg-white/95'
          >
            {t('welcome.loginAnonymous')}
          </Button>
        </div>
      </div>
    </div>
  );
});
