import { Button, type ButtonProps } from '@utima/ui';
import clsx from 'clsx';
import { memo } from 'react';

export interface FeedbackButtonProps extends ButtonProps {}

export const FeedbackButton = memo(function FeedbackButton({
  className,
  ...restProps
}: FeedbackButtonProps) {
  return (
    <Button
      size='xs'
      outline
      className={clsx(
        'min-w-fit grow !rounded-full border-white bg-transparent font-normal text-white',
        className,
      )}
      {...restProps}
    />
  );
});
