import type { StateCreator } from 'zustand';

export type Routes =
  | 'chat'
  | 'welcome'
  | 'eFitnessLogin'
  | 'newMembership'
  | 'newMember'
  | 'membershipConsent'
  | 'newMembershipRecap'
  | 'membershipPayment';

export interface RouterSlice {
  route: Routes;
  history: Routes[];
  pushRoute: (route: Routes) => void;
  popRoute: () => void;
}

export const createRouterSlice: StateCreator<RouterSlice> = set => ({
  history: [],
  route: 'welcome',
  pushRoute: (route: Routes) =>
    set(state => ({ history: [...state.history, state.route], route })),
  popRoute: () =>
    set(state => {
      const history = [...state.history];
      const route = history.pop() || 'welcome';

      return { history, route };
    }),
});
