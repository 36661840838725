import { useMutation, type UseMutationResult } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { API_PARAM_PAYMENT } from '@/constants/AppSettings';
import { aiCoreApi } from '@/services/aiCoreClient';
import { lang } from '@/services/i18n';
import { useBoundStore } from '@/store/store';

interface RegistrationMemberData {
  clubId: number;
  invoiceID: string;
}

interface AccessToken {
  token: string;
}

interface ApiResponse {
  redirectUrl: string;
}

interface MutationParams {
  params: {
    installmentIds: string;
    clubId: number;
    returnUrl: string;
  };
  headers: {
    memberToken: string;
  };
}

export function PaymentMembership() {
  const { popRoute } = useBoundStore(state => ({
    popRoute: state.popRoute,
  }));
  const [accessToken, registrationMemberData] = useBoundStore(state => [
    state.accessToken as AccessToken,
    state.registrationMemberData as RegistrationMemberData,
  ]);
  const { t } = useTranslation();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const mutation: UseMutationResult<ApiResponse, any, MutationParams> =
    useMutation({
      mutationFn: async ({ params, headers }) => {
        return aiCoreApi.postPayment({ data: params, headers: headers });
      },
      onSuccess: data => {
        window.location.href = data.redirectUrl;
      },
      onError: async (err: any) => {
        const errorDecoded = await err.response?.json();
        if (errorDecoded.response.data.errors) {
          setErrorMessages(errorDecoded.response.data.errors);
        } else {
          setErrorMessages(['Something went wrong']);
        }
      },
    });

  const payMembership = async () => {
    const url = new URL(window.location.toString());
    url.searchParams.append(API_PARAM_PAYMENT, 'true');
    const params = {
      installmentIds: registrationMemberData.invoiceID,
      clubId: registrationMemberData.clubId,
      returnUrl: url.toString(),
    };
    const headers = {
      memberToken: `Bearer ${accessToken?.token}`,
      language: lang === 'cs' ? 'cs-CZ' : 'en-US',
    };
    await mutation.mutateAsync({ params, headers });
  };

  return (
    <div className='size-full items-center justify-stretch overflow-auto p-8'>
      <div className='w-full rounded-2xl bg-darkGrey p-5 [&_*]:ring-offset-darkGrey'>
        <div className='relative mb-2 flex w-full items-center justify-center'>
          <Button
            onClick={() => popRoute()}
            className='absolute left-0 top-0 flex text-lg font-medium'
            size='icon-sm'
          >
            <ChevronLeft />
          </Button>
          <h4 className='text-center text-3xl font-normal leading-10 text-white'>
            {t('membershipRegistration.payment')}
          </h4>
        </div>
        <div className='text-center font-bold text-white'>
          <p>{t('membershipRegistration.paymentMessage')}</p>
          {mutation.error &&
            errorMessages.length > 0 &&
            errorMessages.map((error: any, index: number) => (
              <p
                key={index}
                className='text-center text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'
              >
                {error.message}
              </p>
            ))}

          <Button
            size='lg'
            type='submit'
            className='mt-4'
            onClick={payMembership}
          >
            {t('membershipRegistration.pay')}
          </Button>
        </div>
      </div>
    </div>
  );
}
