import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from '@utima/ui';
import { Form, type TypedFormState, Checkbox } from '@utima/ui-informed';
import { HTTPError } from 'ky';
import { ChevronLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { aiCoreApi } from '@/services/aiCoreClient';
import { i18n, lang } from '@/services/i18n';
import { queryKeys } from '@/services/queryClient';
import { useBoundStore } from '@/store/store';

export function MemberShipConsent() {
  const { t } = useTranslation();
  const { pushRoute, popRoute } = useBoundStore(state => ({
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
  }));

  const [registrationMemberData, setRegistrationMemberData] = useBoundStore(
    state => [state.registrationMemberData, state.setRegistrationMemberData],
  );

  const { data, isFetching } = useQuery({
    queryKey: queryKeys.membershipConsents(),
    queryFn: async () => {
      return aiCoreApi.getConsentDefinitions({
        clubId: registrationMemberData.clubId,
        id: registrationMemberData.membershipId,
        language: lang === 'cs' ? 'cs-CZ' : 'en-US',
      });
    },
    retry: 0,
    enabled: true,
  });

  const { mutateAsync, error } = useMutation({
    mutationFn: async (formState: TypedFormState<any>) => {
      setRegistrationMemberData({
        ...registrationMemberData,
        consentsIds: Object.keys(formState.values).filter(
          key => formState.values[key],
        ),
      });
      pushRoute('newMembershipRecap');
    },
  });

  return (
    <div className='size-full items-center justify-stretch overflow-auto p-8 '>
      <div className='w-full rounded-2xl bg-darkGrey p-5 [&_*]:ring-offset-darkGrey'>
        <div className='relative mb-2 flex w-full items-center justify-center'>
          <Button
            onClick={() => popRoute()}
            className='absolute left-0 top-0 flex text-lg font-medium'
            size='icon-sm'
          >
            <ChevronLeft />
          </Button>
          <h4 className='text-center text-3xl font-normal leading-10 text-white'>
            {t('membershipRegistration.consent')}
          </h4>
        </div>
        {isFetching ? (
          <div className='flex h-96 flex-col items-center justify-center'>
            <p
              className='text-center
            text-2xl
          text-white'
            >
              {t('membershipRegistration.loading')}
            </p>
          </div>
        ) : (
          <Form onSubmit={mutateAsync} className='flex flex-col gap-5'>
            {data?.results.map((consent: any) => {
              // CZ and ENG
              const [czechPart, englishPart] = consent.content.split('/');

              return (
                <div key={consent.consentDefinitionId}>
                  <Checkbox
                    name={consent.consentDefinitionId.toString()}
                    required={consent.required}
                    description={
                      i18n.language === 'cs' ? czechPart : englishPart
                    }
                    className='flex flex-row'
                    validate={value => {
                      if (consent.required && !value) {
                        return t('membershipRegistration.requiredField');
                      }
                    }}
                  />
                  {consent?.links?.length > 0 && (
                    <a
                      href={consent?.links[0].url}
                      target='_blank'
                      rel='noreferrer'
                      className='text-sm text-white underline'
                    >
                      {consent?.links[0].text}
                    </a>
                  )}
                </div>
              );
            })}

            {error && (
              <p className='text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'>
                {error instanceof HTTPError && error.response.status === 401
                  ? t('loginForm.errors.401')
                  : t('loginForm.errors.500')}
              </p>
            )}
            <Button size='lg' type='submit' className='mt-4'>
              {t('membershipRegistration.continue')}
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
}
