import type { TypedFormState } from '@utima/ui-informed';
import React from 'react';

import StepCounter from '@/components/multistepForm/StepCounter';

import type { MultiStepFormProps } from './MultipleStepForm';
import { useMultipleStepForm } from './MultipleStepFormContext';

function MultipleStepFormControl({
  children,
  onSubmit,
  showStepCounter,
  showPreviousButton,
}: MultiStepFormProps) {
  const { currentStep, numberOfSteps, goToPreviousStep, goToStep } =
    useMultipleStepForm();

  const renderSteps = (children: React.ReactNode) => {
    return React.Children.map(children, (child, index) => {
      if (index === currentStep) {
        return index === numberOfSteps
          ? React.cloneElement(child as React.ReactElement, {
              // TODO: This should be typed properly
              onSubmit: (state: TypedFormState<any>) => onSubmit(state), // Pass the final onSubmit for the last step to handle
            })
          : child; // Render child without any modifications
      }

      return null;
    });
  };

  const renderPreviousButton = () => {
    if (!showPreviousButton || currentStep <= 0) return null;

    return (
      <button type='button' onClick={goToPreviousStep}>
        Previous
      </button>
    );
  };

  const renderStepCounter = () => {
    if (!showStepCounter) return null;

    return (
      <StepCounter
        totalSteps={numberOfSteps}
        currentStep={currentStep}
        onStepClick={step => goToStep(step)}
      />
    );
  };

  return (
    <>
      {renderStepCounter()}
      {renderSteps(children)}
      {renderPreviousButton()}
    </>
  );
}

export default MultipleStepFormControl;
