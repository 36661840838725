import { AlertDialog, Button } from '@utima/ui';
import { useCallback, useState, type MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmDialogProps = {
  onConfirm?: () => Promise<unknown>;
  onCancel?: () => Promise<unknown>;
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

export function ConfirmDialog({
  open,
  onOpenChange,
  onConfirm,
  onCancel,
}: ConfirmDialogProps) {
  const { t } = useTranslation('glossary');
  const [confirming, setConfirming] = useState(false);
  const [canceling, setCanceling] = useState(false);

  const handleConfirm = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      try {
        setConfirming(true);
        await onConfirm?.();
      } finally {
        onOpenChange(false);
        setConfirming(false);
      }
    },
    [onConfirm, onOpenChange],
  );

  const handleCancel = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      try {
        setCanceling(true);
        await onCancel?.();
        onOpenChange(false);
      } finally {
        setCanceling(false);
      }
    },
    [onCancel, onOpenChange],
  );

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Content className='w-4/5 bg-darkGrey'>
        <AlertDialog.Header>
          <AlertDialog.Title>
            {t('glossary.confirmationMembership')}
          </AlertDialog.Title>
          <AlertDialog.Description>
            {t('glossary.confirmMessage')}
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel asChild>
            <Button
              onClick={handleCancel}
              loading={canceling}
              disabled={canceling || confirming}
            >
              {t('glossary.no')}
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action asChild>
            <Button
              onClick={handleConfirm}
              loading={confirming}
              disabled={canceling || confirming}
            >
              {t('glossary.yes')}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
