import { useMutation } from '@tanstack/react-query';
import { Button, SelectPrimitive } from '@utima/ui';
import { Input, Select, type TypedFormState } from '@utima/ui-informed';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import MultipleStepForm from '@/components/multistepForm/MultipleStepForm';
import StepForm from '@/components/multistepForm/StepForm';
import { DateFormat, format } from '@/lib/formatter';
import { aiCoreApi } from '@/services/aiCoreClient';
import { lang } from '@/services/i18n';
import { AuthSource } from '@/store/slices/userSlice';
import { useBoundStore } from '@/store/store';

type FormValues = {
  club: string;
  first_name: string;
  last_name: string;
  password: string;
  email: string;
  mobile_phone: string;
  mobile_phone_prefix: string;
  gender: string;
  birthDate: string;
  street: string;
  city: string;
  zip: string;
};

function updateErrorMessage(
  errors: any[],
  targetCode: string,
  targetPath: string,
  newMessage: string,
): any[] {
  return errors.map(error => {
    if (error.responseCode === targetCode && error.path === targetPath) {
      return { ...error, message: newMessage };
    }

    return error;
  });
}

const dateMask = (value: string) => {
  return format.date(value, DateFormat.DateInput);
};

/*
 * This function is used to limit the year to the current year.
 * @param years - The number of years to limit the year to. (for example limit to max 100 years in the past)
 */
const limitToMinYear = (years: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - years);

  return date;
};

export function NewMember() {
  const { t } = useTranslation();
  const [clubId, setClubId] = useState('895');
  const { login, pushRoute, popRoute, user } = useBoundStore(state => ({
    login: state.login,
    pushRoute: state.pushRoute,
    popRoute: state.popRoute,
    user: state.user,
  }));
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // TODO: This is here as a workaround to show long error message, since @utima/ui does not support long error messages
  //       as they clip into the inputs. This should be removed once the issue is fixed in the library.
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined,
  );

  const validateZipCode = (val: string) => {
    const zipCodeRegex = /^\d{5}$/;
    if (!zipCodeRegex.test(val)) return t('validation.invalidZipCode');
  };

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!"#$%&()*,.:<>?@^{|}]/.test(password);

    if (
      password.length < minLength ||
      !hasUpperCase ||
      !hasNumber ||
      !hasSpecialCharacter
    ) {
      setPasswordError(t('memberRegistration.invalidPassword'));

      return false;
    }
    setPasswordError(undefined);
  };

  const { mutateAsync, error, isPending } = useMutation({
    mutationFn: async (formState: TypedFormState<FormValues>) => {
      const {
        club,
        first_name,
        last_name,
        email,
        password,
        mobile_phone,
        mobile_phone_prefix,
        gender,
        birthDate,
        street,
        city,
        zip,
      } = formState.values;
      setClubId(club);

      return aiCoreApi.postMember({
        firstName: first_name,
        lastName: last_name,
        email: email,
        password: password,
        clubId: club,
        language: lang === 'cs' ? 'cs-CZ' : 'en-US',
        phone: mobile_phone,
        phonePrefix: mobile_phone_prefix,
        address: street,
        gender,
        birthDate,
        city,
        postalCode: zip,
      });
    },
    onSuccess: data => {
      login(
        {
          id: user?.id!,
          externalId: user?.externalId!,
          source: AuthSource.Efitness,
          club: clubId,
        },
        {
          token: data.authorizationData.accessToken,
          expires: data.authorizationData.expires,
        },
        data.authorizationData.refreshToken,
      );
      pushRoute('newMembership');
    },
    onError: async (err: any) => {
      const errorDecoded = await err.response?.json();
      if (errorDecoded.response.data.errors) {
        const updatedErrors = updateErrorMessage(
          errorDecoded.response.data.errors,
          '4.24',
          'password',
          t('memberRegistration.invalidPassword'),
        );
        setErrorMessages(updatedErrors);
      } else {
        setErrorMessages(['Something went wrong']);
      }
    },
  });

  return (
    <div className='size-full items-center justify-stretch overflow-auto p-8'>
      <div className='w-full rounded-2xl bg-darkGrey p-5 [&_*]:ring-offset-darkGrey'>
        <div className='relative mb-2 flex w-full items-center justify-center'>
          <Button
            onClick={() => popRoute()}
            className='absolute left-0 top-0 flex text-lg font-medium'
            size='icon-sm'
          >
            <ChevronLeft />
          </Button>
          <h4 className='text-center text-3xl font-normal leading-10 text-white'>
            {t('memberRegistration.newMember')}
          </h4>
        </div>
        <MultipleStepForm showStepCounter onSubmit={mutateAsync}>
          <StepForm
            zodSchema={z
              .object({
                email: z
                  .string()
                  .email({ message: t('validation.invalidEmailFormat') }),
                mobile_phone: z
                  .string()
                  .refine((val: string) => /^\d+$/.test(val), {
                    message: t('validation.invalidPhoneFormat'),
                  }),
              })
              .strict()}
            validateOn='change-submit'
            className='flex flex-col gap-5'
          >
            <Select
              size='lg'
              defaultValue='895'
              required
              name='club'
              usePortal={false}
              label={t('membershipForm.club')}
            >
              <SelectPrimitive.Group>
                <SelectPrimitive.Label>Praha</SelectPrimitive.Label>
                <Select.Item value='895'>Anděl</Select.Item>
                <Select.Item value='1185'>Butovice</Select.Item>
                <Select.Item value='1184'>Eden</Select.Item>
                <Select.Item value='1186'>Harfa</Select.Item>
                <Select.Item value='1048'>Hostivař</Select.Item>
                <Select.Item value='896'>Karlín</Select.Item>
                <Select.Item value='2087'>Letňany</Select.Item>
                <Select.Item value='1187'>Palladium</Select.Item>
                <Select.Item value='1760'>Pankrác</Select.Item>
                <Select.Item value='2086'>SO-HO</Select.Item>
                <Select.Item value='1042'>Stodůlky</Select.Item>
                <Select.Item value='1041'>Václavské náměstí</Select.Item>
                <Select.Item value='1043'>Vinohradská</Select.Item>
                <Select.Item value='1047'>Vršovická</Select.Item>
              </SelectPrimitive.Group>
              <SelectPrimitive.Group>
                <SelectPrimitive.Label>Brno</SelectPrimitive.Label>
                <Select.Item value='1912'>Lužánky</Select.Item>
                <Select.Item value='1911'>Vlněna</Select.Item>
              </SelectPrimitive.Group>
              <SelectPrimitive.Group>
                <SelectPrimitive.Label>Ostrava</SelectPrimitive.Label>
                <Select.Item value='1804'>Ostrava Avion</Select.Item>
              </SelectPrimitive.Group>
            </Select>
            <Input
              name='first_name'
              label={t('memberRegistration.firstName')}
              required
            />
            <Input
              required
              name='last_name'
              label={t('memberRegistration.surName')}
            />
            <Input
              name='email'
              type='email'
              required
              label={t('memberRegistration.email')}
            />
            <div className='flex flex-row justify-around gap-2'>
              <Select
                size='md'
                defaultValue='+420'
                required
                className='w-1/4'
                name='mobile_phone_prefix'
                usePortal={false}
                label={t('memberRegistration.mobilePhonePrefix')}
              >
                <Select.Item value='+420'>+420</Select.Item>
                <Select.Item value='+421'>+421</Select.Item>
              </Select>
              <Input
                className='w-3/4'
                name='mobile_phone'
                required
                label={t('memberRegistration.mobilePhone')}
              />
            </div>
            <Input
              name='password'
              passwordPreview
              type='password'
              min={8}
              validate={val => validatePassword(String(val))}
              required
              label={t('memberRegistration.password')}
            />
            {passwordError && (
              <p className='-bottom-3 -mt-3 text-xs text-danger animate-in fade-in'>
                {passwordError}
              </p>
            )}
            <Button
              size='lg'
              type='submit'
              disabled={isPending}
              loading={isPending}
            >
              {t('buttons.nextStep')}
            </Button>
          </StepForm>
          <StepForm className='flex flex-col gap-5'>
            <Select
              name='gender'
              size='lg'
              defaultValue=''
              label={t('memberRegistration.gender')}
              usePortal={false}
              required
            >
              <Select.Item value='male'>{t('gender.male')}</Select.Item>
              <Select.Item value='female'>{t('gender.female')}</Select.Item>
              <Select.Item value='other'>{t('gender.other')}</Select.Item>
            </Select>
            <Input
              label={t('memberRegistration.birthDate')}
              name='birthDate'
              type='date'
              required
              min={format.date(limitToMinYear(100), DateFormat.DateInput)}
              max={format.date(new Date(), DateFormat.DateInput)}
              /* @ts-expect-error Property 'mask' does not exist on type 'IntrinsicAttributes & InputProps' */
              mask={dateMask}
            />
            <Input
              name='street'
              label={t('memberRegistration.street')}
              required
            />
            <Input name='city' label={t('memberRegistration.city')} required />
            <Input
              name='zip'
              maxLength={5}
              validate={val => validateZipCode(String(val))}
              label={t('memberRegistration.zipCode')}
              required
            />
            {error &&
              errorMessages.length > 0 &&
              errorMessages.map((error: any) => (
                <p
                  key={error.responseCode}
                  className='text-center text-sm font-medium text-rose-600 animate-in fade-in slide-in-from-top'
                >
                  {error.message}
                </p>
              ))}
            <Button
              size='lg'
              type='submit'
              disabled={isPending}
              loading={isPending}
            >
              {t('loginForm.submit')}
            </Button>
          </StepForm>
        </MultipleStepForm>
      </div>
    </div>
  );
}
