import { useMutation } from '@tanstack/react-query';

import type { Message, Role } from '@/hooks/chatTypes';
import { adminApi, aiCoreApi } from '@/services/aiCoreClient';
import { useBoundStore } from '@/store/store';

import type { InsertOptions } from './Faqs';

interface FaqTileProps {
  id: string;
  title: string;
  Question: string;
  Answer: string;
  insert: (
    message: Message | string,
    role?: Role,
    options?: InsertOptions,
  ) => void;
}

export function FaqTile({ id, title, Question, Answer, insert }: FaqTileProps) {
  const [user, threadId] = useBoundStore(state => [state.user, state.threadId]);
  const mutation = useMutation({
    mutationFn: () => adminApi.incrementFaqs(id),
  });
  const handleClick = async () => {
    await mutation.mutateAsync();
    insert(Question);
    insert(Answer, 'system', { timeout: 5000 });
    const data = {
      userMessage: Question,
      systemMessage: Answer,
      userId: user!.id!.toString(),
      threadId: threadId!,
    };

    aiCoreApi.saveConversation(data, {
      timeout: 120000,
    });
  };

  return (
    <div
      tabIndex={0}
      className='flex cursor-pointer items-center bg-red px-4 py-1 text-center font-roboto font-bold text-white transition duration-300 ease-in-out hover:bg-darkRed hover:shadow-lg'
      onClick={handleClick}
    >
      {title.toUpperCase()}
    </div>
  );
}
