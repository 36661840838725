import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
  type ChangeEvent,
  type FormEvent,
} from 'react';
import { v4 as uuid } from 'uuid';

import { aiCoreApi } from '@/services/aiCoreClient';
import { queryKeys } from '@/services/queryClient';
import type { MemberInfo } from '@/types/memberInfo';
import type { Membership } from '@/types/membership';

import type { Message, Role, UseChatParams, UseChatReturn } from './chatTypes';

const INITIAL_MESSAGES: Message[] = [];

/**
 * Helper function to create a new message object.
 */
export function createMessage(
  message: string | Message,
  role: Role,
  conversationId?: string,
): Message {
  return typeof message === 'string'
    ? {
        role: role,
        type: 'text',
        content: message,
        createdAt: new Date().toISOString(),
        id: uuid(),
        conversationId,
      }
    : message;
}

/**
 * Custom hook to handle chat logic, sending and receiving messages,
 * and managing chat history.
 */
export function useChat({
  initialInput = '',
  initialMessages = INITIAL_MESSAGES,
  threadId,
  user,
  member,
  membership,
  onMessage,
}: UseChatParams): UseChatReturn {
  const [_, startTransition] = useTransition();
  const [input, setInput] = useState(initialInput);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(!!(user?.id && threadId));
  const abortController = useRef<null | AbortController>(null);

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [...queryKeys.userChat(user?.id), threadId],
    [user?.id, threadId],
  );

  /**
   * Fetch message history, we also use this to store the chat
   * history in the state. This automatically handles cache invalidation
   * when thread is changed.
   */
  const { data: messages, isFetching: isInitializingMessages } = useQuery<
    Message[]
  >({
    queryKey: queryKey,
    queryFn: async () => {
      try {
        const data = await aiCoreApi.getMessages({
          userId: user!.id,
          threadId: threadId!,
        });

        /**
         * Flatten the conversation array to a single array of messages.
         * We also sort the messages by createdAt date.
         */
        const result = data
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
          )
          .reduce<Message[]>((acc, item) => {
            item.data.forEach((message: Message) => {
              acc.push({
                ...message,
                conversationId: item.id,
              });
            });

            return acc;
          }, []);

        return result.length ? result : initialMessages;
      } catch (error) {
        console.error(error);

        return [];
      }
    },
    placeholderData: INITIAL_MESSAGES,
    // We want to keep the messages in the cache forever
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!user?.id && !!threadId,
  });

  /**
   * Abort the current request.
   */
  const abort = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
  }, []);

  /**
   * Mutation function to send message to the API.
   */
  const { mutateAsync: mutateChat } = useMutation({
    mutationFn: (data: {
      userId: string;
      message: string;
      threadId: string;
      member: MemberInfo | null;
      membership: Membership | null;
    }) => {
      // Abort existing request
      if (
        abortController.current?.signal &&
        !abortController.current.signal.aborted
      ) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      return aiCoreApi.chat(data, {
        timeout: 120000,
        signal: abortController.current.signal,
      });
    },
  });

  useEffect(() => {
    setIsInitialized(!!(user?.id && threadId));
  }, [threadId, user]);

  /**
   * Insert new message to state, without sending it to the API.
   */
  interface InsertOptions {
    timeout?: number;
  }

  const insert = useCallback(
    (
      message: Message | string,
      role: Role = 'user',
      options?: InsertOptions,
    ) => {
      const { timeout = 0 } = options || {};
      if (timeout > 0) {
        setIsLoading(true);
      }

      (async () => {
        if (timeout > 0) {
          await new Promise(resolve => setTimeout(resolve, timeout));
        }

        queryClient.setQueryData(queryKey, (prev: Message[]) => [
          ...(prev ?? []),
          createMessage(message, role),
        ]);
        setIsLoading(false);
      })();
    },
    [queryClient, queryKey],
  );

  /**
   * Create new message, add it to the state and send it to the API.
   */
  const append = useCallback(
    async (message: Message | string) => {
      if (!user || !threadId) {
        throw new Error('User or threadId is not defined');
      }

      startTransition(() => {
        insert(message);
        setIsLoading(true);
      });

      try {
        const response = await mutateChat({
          userId: user.id,
          threadId: threadId,
          message: typeof message === 'string' ? message : message.content,
          member: member,
          membership: membership,
        });

        const newMessage = createMessage(
          response.message,
          'system',
          response.conversation.id,
        );

        insert(newMessage);
        onMessage?.(newMessage);
      } catch (error) {
        setError(error);
      } finally {
        startTransition(() => {
          setIsLoading(false);
        });
      }
    },
    [insert, mutateChat, onMessage, user, threadId],
  );

  /**
   * Input/textarea on change handler, automatically updates input value.
   */
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      setInput(e.target.value);
    },
    [],
  );

  /**
   * Handle form submission. Sends message to the API and resets input field.
   */
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      startTransition(() => {
        append(input);
        setInput('');
      });
    },
    [append, input],
  );

  return {
    error,
    isLoading,
    isInitialized: !isInitializingMessages && isInitialized,
    messages: messages ?? INITIAL_MESSAGES,
    input,
    setInput,
    handleSubmit,
    handleInputChange,
    abort,
    append,
    insert,
  };
}
